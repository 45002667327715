import { graphql } from "gatsby";
import React from "react";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import BlogLayout from "../containers/BlogLayout";
import MediumCta from "../components/Blog/MediumCta";
import Section from "../containers/Section";
import Recommendations from "../components/Blog/Recommendations";
import { nav } from "../utils/navigation";
import { Name, Type, Location } from "../utils/analytics/constants";
import { CTA } from "../constants/text";
import { URL } from "../constants/destinations";
import { useTranslation } from 'gatsby-plugin-react-i18next';

// TODO: pass more info to match Figma Designs
const Blog: React.FC<{}> = props => {
  const publications = props?.data?.publications?.edges;
  console.log(publications);

  const {t} = useTranslation();

  return (
    <Layout>
      <SEO
        title={t("eCommerce Advertising Blog")}
        description={
          t("Perpetua's blog for eCommerce advertising news, insights tips and content for sellers, brands and agencies looking to grow their business.")
        }
      />
      <BlogLayout />
      <div className="mx-0 px-0 lg:mx-auto w-full lg:max-w-8xl">
        <Recommendations publications={publications} title="Top Stories" />
      </div>
      <Section>
        <MediumCta
          title={t("Getting started is easy")}
          description={t("Everything you need to grow your ecommerce business, in one platform")}
          primaryButtonText={t(CTA.GetStarted)}
          secondaryButtonText={t(CTA.Demo)}
          secondaryButtonId="bottom-cta-get-started"
          primaryButtonOnClick={() => {
            nav(URL.SignUp);
          }}
        />
      </Section>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    publications:allContentfulPublication(
      limit: 4
      sort: { fields: createdAt, order: DESC }
      filter: {node_locale: {eq: "zh-CN"}, title: {ne: null}}
    ) {
      edges {
        node {
          id
          slug
          title
          timeToRead
          publishDate(formatString: "MMMM Do, YYYY")
          bodyPreview
          bannerImage {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          categories {
            name
          }
        }
      }
    }
  }
`;
