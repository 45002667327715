import classNames from "classnames";
import React from "react";
import Button, { ButtonSize, ButtonVariant } from "../../Button";

interface MediumCTAProps {
  title: string;
  image?: any;
  description?: string | React.ReactElement;
  variant?: string;
  primaryButtonText?: string;
  primaryButtonId?: string;
  primaryButtonOnClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  secondaryButtonText?: string;
  secondaryButtonId?: string;
  secondaryButtonOnClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  hex?: string;
}

const MediumCta: React.FC<MediumCTAProps> = props => {
  const {
    title,
    image,
    description,
    primaryButtonText = "Get Started",
    primaryButtonId,
    primaryButtonOnClick,
    secondaryButtonText,
    secondaryButtonId,
    secondaryButtonOnClick,
    hex
  } = props;

  return (
    <div
      style={{ backgroundColor: hex ?? "#4338CA" }}
      className={` ${
        hex ?? "bg-gradient-to-b from-indigo-1 to-indigo-1-dark"
      } ${
        image
          ? "flex-col-reverse lg:flex-row shadow rounded-xl py-20 px-10"
          : "justify-center shadow rounded-xl py-20 md:py-18"
      } flex max-w-full`}
    >
      <div
        className={classNames(
          `${
            image
              ? "lg:text-left"
              : "items-center max-w-full px-9 md:max-w-xs lg:max-w-5xl lg:px-24"
          }`,
          "text-center"
        )}
      >
        <h2 className="text-4xl font-semibold text-white lg:font-bold">
          {title}
        </h2>
        <p className="text-xl font-normal text-white pt-3 pb-6 lg:pt-5">
          {description}
        </p>
        <div
          className={`flex ${
            image ? "justify-center lg:justify-start" : "justify-center"
          } space-x-4 ${image ? " lg:mx-0" : "mx-auto"}`}
        >
          {primaryButtonText && (
            <Button
              id={primaryButtonId}
              text={primaryButtonText}
              variant={ButtonVariant.primaryWhite}
              size={ButtonSize.large}
              onClick={primaryButtonOnClick}
            />
          )}
          {secondaryButtonText && (
            <Button
              id={secondaryButtonId}
              text={secondaryButtonText}
              variant={ButtonVariant.secondaryWhite}
              size={ButtonSize.large}
              onClick={secondaryButtonOnClick}
            />
          )}
        </div>
      </div>
      {image && (
        <div
          className="mb-5 lg:mb-0 mx-auto w-1/3 box-content"
          style={{
            content: `url(${image})`,
            objectFit: "contain"
          }}
        />
      )}
    </div>
  );
};

export default MediumCta;
