import React from "react";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AdSchoolCta: React.FC<Record<string, unknown>> = props => {
  const data = useStaticQuery(graphql`
    query {
      topImage: file(relativePath: { eq: "blogImages/AdSchool.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const {t} = useTranslation();

  return (
    <div className="flex flex-col bg-gradient-to-b from-blue-2 py-9 to-purple-3 px-6 shadow rounded-xl">
      <img src={data?.topImage?.childImageSharp?.fluid.src} />
      <p className="text-lg font-semibold pb-4 pt-6">
        {t("Take your marketplace advertising to the next level.")}
      </p>
      <p className="text-sm text-gray-1 pb-6">
        {t(`Ad School's free, online courses are developed by experts to help you grow your career and your business.`)}
      </p>
      <Button
        text={t("Take the classes")}
        size={ButtonSize.large}
        variant={ButtonVariant.primary}
        className="w-full lg:w-max"
        onClick={() => {
          window.open("https://adschool.perpetua.io/", "_blank");
        }}
      />
    </div>
  );
};

export default AdSchoolCta;
