import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Navigation from "../../components/Blog/Navigation";
import Section from "../Section";
import BlogList from "../../components/Blog/BlogList";
import AdSchoolCta from "../../components/Blog/AdSchoolCta";
import MediumCta from "../../components/Blog/MediumCta";
import walmartImage from "../../images/Walmart/walmart.png";
import useMedia from "use-media";
import { nav } from "../../utils/navigation";
import { CTA } from "../../constants/text";
import { URL } from "../../constants/destinations";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const BlogLayout: React.FC<{}> = (props: any) => {
  const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);
  const isMobile = useMedia({ maxWidth: "768px" });

  const {t} = useTranslation();

  const data = useStaticQuery(graphql`
    query($language: String){
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            language
          }
        }
      }
      news: allContentfulPublication(
        filter: {categories: {elemMatch: {name: {eq: "News"}}}, node_locale: {eq: $language}, slug: {ne: null}}
        limit: 7
        sort: {order: DESC, fields: createdAt}
      ) {
        edges {
          node {
            id
            slug
            title
            bodyPreview
            bannerImage {
              fluid(quality: 90) {
                src
              }
            }
            categories {
              name
            }
            timeToRead
            publishDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
      customers: allContentfulPublication(
        filter: {categories: {elemMatch: {name: {eq: "Customer Stories"}}}, node_locale: {eq: $language}, slug: {ne: null}}
        limit: 7
        sort: {order: DESC, fields: createdAt}
      ) {
        edges {
          node {
            id
            slug
            title
            bodyPreview
            bannerImage {
              fluid(quality: 90) {
                src
              }
            }
            categories {
              name
            }
            timeToRead
            publishDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
      advertising: allContentfulPublication(
        filter: {categories: {elemMatch: {name: {eq: "Advertising"}}}, node_locale: {eq: $language}, slug: {ne: null}}
        limit: 7
        sort: {order: DESC, fields: createdAt}
      ) {
        edges {
          node {
            id
            slug
            title
            bodyPreview
            bannerImage {
              fluid(quality: 90) {
                src
              }
            }
            categories {
              name
            }
            timeToRead
            publishDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
      product: allContentfulPublication(
        filter: {categories: {elemMatch: {name: {eq: "Product News"}}}, node_locale: {eq: $language}, slug: {ne: null}}
        limit: 7
        sort: {order: DESC, fields: createdAt}
      ) {
        edges {
          node {
            id
            slug
            title
            bodyPreview
            bannerImage {
              fluid(quality: 90) {
                src
              }
            }
            categories {
              name
            }
            timeToRead
            publishDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
      retail: allContentfulPublication(
        filter: {categories: {elemMatch: {name: {eq: "Retail Media"}}}, node_locale: {eq: $language}, slug: {ne: null}}
        limit: 7
        sort: {order: DESC, fields: createdAt}
      ) {
        edges {
          node {
            id
            slug
            title
            bodyPreview
            bannerImage {
              fluid(quality: 90) {
                src
              }
            }
            categories {
              name
            }
            timeToRead
            publishDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
      marketplace: allContentfulPublication(
        filter: {categories: {elemMatch: {name: {eq: "Marketplaces"}}}, node_locale: {eq: $language}, slug: {ne: null}}
        limit: 7
        sort: {order: DESC, fields: createdAt}
      ) {
        edges {
          node {
            id
            slug
            title
            bodyPreview
            bannerImage {
              fluid(quality: 90) {
                src
              }
            }
            categories {
              name
            }
            timeToRead
            publishDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  `);

  React.useEffect(() => {
    if (isMobile) {
      setShowMobileNav(false);
    }
  }, [isMobile]);

  
  const navigation = [
    {
      link: "/blog",
      text: "All Articles"
    },
    {
      link: "/blog/category/news/",
      text: "News"
    },
    {
      link: "/blog/category/retail%20media/",
      text: "Retail Media"
    },
    {
      link: "/blog/category/marketplaces/",
      text: "Marketplaces"
    },
    {
      link: "/blog/category/advertising/",
      text: "Advertising"
    },
    {
      link: "/blog/category/customer%20stories/",
      text: "Customer Stories"
    }
  ];

  // Article types to pass into the blog list variations
  const newsArticles = data?.news?.edges;
  const customerArticles = data?.customers?.edges;
  const retailArticles = data?.retail?.edges;
  const marketplaceArticles = data?.marketplace?.edges;
  const advertisingArticles = data?.advertising?.edges;

  /** Mobile Navigation */
  const mobileNav = (
    <ul className="font-semibold py-13 col-span-8 rounded-lg">
      {navigation.map((item, i) => {
        return (
          <li className="flex mx-1 col-span-8">
            <Link className="py-4 px-8 w-full" key={i} to={item.link}>
              {t(item.text)}
            </Link>
          </li>
        );
      })}
    </ul>
  );

  return (
    <Section className="grid grid-cols-8 lg:gap-4 pt-60 lg:pt-48">
      {/* Left column */}
      <div className="col-span-8 lg:col-span-2 lg:pr-5 lg:block">
        <Navigation
          header={t("Insights & growth rocket fuel")}
          description={t("Expert advice, insider news and industry insights to help you crush your advertising goals.")}
        />
      </div>
      <div className="bg-white col-span-8 sticky top-20 z-10 md:hidden">
        {isMobile && (
          <button
            className="w-full lg:hidden col-span-8 text-2xl font-semibold py-7 focus:outline-none"
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            Filter
          </button>
        )}
        {showMobileNav && mobileNav}
      </div>
      {/* Right column grow */}
      <div className="pt-8 col-span-8 lg:col-span-6">
        <BlogList
          publications={newsArticles}
          title={t("News")}
          ctaPosition={4}
          CTA={AdSchoolCta}
          color="text-black"
        />
        <BlogList
          publications={retailArticles}
          title={t("Retail Media")}
          color="text-indigo-1"
        />
        <BlogList
          publications={marketplaceArticles}
          title={t("Marketplaces")}
          color="text-red-1"
        />
        <BlogList
          publications={advertisingArticles}
          title={t("Advertising")}
          color="text-orange-1"
          ctaPosition={4}
          ctaSize="medium"
          CTA={() => (
            <MediumCta
              primaryButtonId="blog-list-advertising"
              title={t("Walmart ads are here!")}
              description={t("Manage Sponsored Ads on Walmart with Perpetua.")}
              primaryButtonText={t(CTA.GetStarted)}
              hex="#42A5F5"
              image={walmartImage}
              primaryButtonOnClick={() => {
                nav(URL.SignUp);
              }}
            />
          )}
        />
        <BlogList
          publications={customerArticles}
          title={t("Customer Stories")}
          color="text-indigo-1"
          ctaPosition={4}
          ctaSize="medium"
          CTA={() => (
            <MediumCta
              primaryButtonId="blog-list-customer"
              title={t("Getting started is easy")}
              description={t("Everything you need to grow your ecommerce business, in one platform")}
              primaryButtonText={t(CTA.GetStarted)}
              primaryButtonOnClick={() => {
                nav(URL.SignUp);
              }}
            />
          )}
        />
      </div>
    </Section>
  );
};

export default BlogLayout;